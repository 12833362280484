interface HistoryState {
	urls: string[]
}

export default {
	name: 'history',
	store() {
		return {
			urls: [] as string[],
			maxEntries: 5,

			init() {
				this.loadFromStorage()
				this.addCurrentUrl()

				// Listen for navigation events
				window.addEventListener('popstate', () => {
					this.addCurrentUrl()
				})
			},

			loadFromStorage() {
				try {
					const stored = localStorage.getItem('alpine-history')
					if (stored) {
						const state: HistoryState = JSON.parse(stored)
						this.urls = state.urls
					}
				} catch (error) {
					console.error('Failed to load history from storage:', error)
				}
			},

			saveToStorage() {
				try {
					const state: HistoryState = { urls: this.urls }
					localStorage.setItem('alpine-history', JSON.stringify(state))
				} catch (error) {
					console.error('Failed to save history to storage:', error)
				}
			},

			addCurrentUrl() {
				const currentUrl = window.location.href

				// Don't add if it's the same as the last URL
				if (this.urls[0] === currentUrl) return

				// Add to beginning of array
				this.urls.unshift(currentUrl)

				// Keep only the most recent URLs
				if (this.urls.length > this.maxEntries) {
					this.urls = this.urls.slice(0, this.maxEntries)
				}

				this.saveToStorage()
			},

			getReferrer(): string | null {
				return this.urls[1] || null
			},

			getHistory(): string[] {
				return [...this.urls]
			},

			getHistoryAt(index: number): string | null {
				return this.urls[index] || null
			}
		}
	}
}
